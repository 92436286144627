<template>
  <div>
    <v-alert
      v-if="this.user?.freeTrial"
      type="success"
      border="left"
      text
    >
      <span class="mr-2"
        >
        You have activated your 7 Free Trial which will end on {{ endDate }}
        </span
      >
      <div v-html="plan?.rejectReason" />
    </v-alert>
    <preloader v-if="status.getting" />

    <membership-plan v-if="!status.getting" class="mb-10" :active="active" />

    <v-card class="mb-5 box-shadow" v-if="cards.length" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Card Details</span>
        <v-spacer></v-spacer>
        <v-btn
          @click="getCustomerPortal()"
          :loading="pricingStatus.gettingSession"
          color="primary"
          depressed
          small
          >Update Billing Details</v-btn
        >
      </v-card-title>

      <v-simple-table dense>
        <template slot:default>
          <thead>
            <tr>
              <th>Brand</th>
              <th class="text-center">Last 4 Digits</th>
              <th class="text-center">Country</th>
              <th class="text-center">Expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="card in cards" :key="card.id">
              <td class="text-capitalize caption">{{ card.card.brand }}</td>
              <td class="text-center caption">{{ card.card.last4 }}</td>
              <td class="text-center caption">{{ card.card.country }}</td>
              <td class="text-center caption">
                {{ card.card.exp_month }}/{{ card.card.exp_year }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card
      class="mb-5 box-shadow"
      v-if="configured.length && !status.getting"
      outlined
    >
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Subscription</span>
      </v-card-title>

      <v-simple-table dense>
        <template slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount</th>
              <th>Interval</th>
              <th>Effective Date</th>
              <th>Next Invoice Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sub in configured" :key="sub.id">
              <td class="caption">{{ price(sub.prices[0].id).description }}</td>
              <td class="caption">
                ${{ (price(sub.prices[0].id).unit_amount / 100) | amount
                }}<span v-if="price(sub.prices[0].id).type == 'recurring'"
                  >/{{ price(sub.prices[0].id).interval }}</span
                >
              </td>
              <td class="caption">
                {{ price(sub.prices[0].id).interval_count }}
              </td>
              <td class="caption">
                {{ sub.current_period_start | formatDate }}
              </td>
              <td
                class="caption"
                :class="{ 'orange--text': sub.status == 'canceled' }"
              >
                <span v-if="sub.status == 'canceled'">Active until</span>
                {{ sub.current_period_end | formatDate }}
              </td>
              <td :class="getStatusColor(sub.status)">{{ sub.status }}</td>
              <td width="10">
                <v-btn
                  v-if="sub.status == 'active'"
                  @click="showConfirmDialog(sub)"
                  x-small
                  color="error"
                  depressed
                >
                Cancel Subscription
                </v-btn>
                <!-- <v-menu
                  v-if="sub.status == 'active'"
                  bottom
                  left
                  offset-y
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small>
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="showConfirmDialog(sub)" dense>
                      <v-list-item-icon class="mr-2"
                        ><v-icon small>mdi-cancel</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>Cancel Subscription</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card v-if="invoices.length" class="box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Invoice</span>
      </v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Amount Paid</th>
              <th>Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td class="caption">{{ invoice.number }}</td>
              <td class="caption">
                ${{ (invoice.amount_paid / 100) | amount }}
                <span class="text-uppercase">{{ invoice.currency }}</span>
              </td>
              <td class="caption">
                {{ (invoice.created * 1000) | formatDate }}
              </td>
              <td class="caption">{{ invoice.status }}</td>
              <td class="text-right caption">
                <a
                  :href="invoice.hosted_invoice_url"
                  target="_blank"
                  class="info--text text-decoration-none underline-on-hover"
                >
                  Invoice
                  <v-icon color="info" class="ml-1" small
                    >mdi-chevron-right</v-icon
                  >
                </a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog :value="showDialog" no-click-animation max-width="400" persistent>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          WARNING: If you cancel your subscription you will lose access to your
          account. Click 'CANCEL' if you wish to not proceed.
          <div class="mt-5">
            <v-btn
              @click="showDeleteDialog"
              color="accent"
              class="mr-2"
              depressed
              >Proceed</v-btn
            >

            <v-btn depressed @click="cancelConfirmDialog">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showDialog1"
      no-click-animation
      max-width="400"
      persistent
    >
      <v-card class="pt-5">
        <!-- <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold"></span>
          </v-card-title> -->
        <v-card-text>
          <div class="text-subtitle-1">
            We're sorry to see you go. Our mission is to save health
            professionals time when creating a diamond-level nutrition service
            for their clients. Please select the reason for cancelling your
            subscription so that we can further enhance the service we offer
          </div>
          <v-radio-group v-model="answer">
            <v-radio
              v-for="(label, i) in radioLabels"
              :key="i"
              :label="label"
              :value="label"
            ></v-radio>
          </v-radio-group>

          <v-textarea
            v-show="answer == 'Something else'"
            outlined
            name="input-7-4"
            label="Comment"
            :value="answer1"
            v-model="answer1"
          ></v-textarea>

          <div class="mt-5">
            <v-btn
              v-if="answer != 'Something else'"
              :loading="loading"
              :disabled="!answer"
              @click="cancelConfirmed"
              color="error"
              class="mr-2"
              depressed
              >Cancel Subscription</v-btn
            >

            <v-btn
              v-else
              :loading="loading"
              :disabled="!answer1"
              @click="cancelConfirmed"
              color="error"
              class="mr-2"
              depressed
              >Cancel Subscription</v-btn
            >

            <v-btn depressed @click="cancelDeleteDialog">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Renew -->
    <renew :show="showRenew" @close="closeRenew()" />
  </div>
</template>

<script>
import moment from 'moment';
import pluralize from 'pluralize';
import _orderBy from 'lodash/orderBy';
import Renew from '@/components/Renew';
import { mapState, mapGetters, mapActions } from 'vuex';
import MembershipPlan from '@/components/MembershipPlan';

export default {
  name: 'Subscription',

  metaInfo: {
    title: 'Subscription',
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pluralize,
      answer: null,
      answer1: null,
      toCancel: null,
      loading: false,
      showRenew: false,
      showDialog: false,
      showDialog1: false,
      radioLabels: [
        'Just not what I need right now.',
        'Too expensive',
        "I'm not sure how to use MEALZEE",
        'Something else',
      ],
      confirmMessage: null,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MembershipPlan,
    Renew,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      cards: (state) => state.pricing.cards,
      plans: (state) => state.pricing.plans,
      invoices: (state) => state.pricing.invoices,
      status: (state) => state.subscriptions.status,
      pricingStatus: (state) => state.pricing.status,
      payments: (state) => state.subscriptions.payments,
      subs: (state) => state.subscriptions.subscriptions,
    }),

    ...mapGetters('subscriptions', ['active']),

    configured: function () {
      let subscriptions = [];
      subscriptions = this.subs.concat(this.payments);

      return _orderBy(
        subscriptions,
        (sub) => {
          return typeof sub.created == 'object'
            ? sub.created.toDate()
            : sub.created * 1000;
        },
        'desc'
      );
    },

    endDate: function () {
      if(!this.user?.trial_end) return ''
      else return moment.unix(this.user?.trial_end.seconds).format('dddd, MMMM Do YYYY')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('subscriptions', [
      'cancelSubscription',
      'getSubscriptions',
      'getTrialingPayments',
      'getPayments',
    ]),

    ...mapActions('pricing', ['getCustomerPortal', 'getInvoices', 'getCards']),

    getLabelColor(status) {
      let colors = {
        paid: 'success',
        pending: 'warning',
        unpaid: 'danger',
      };

      return colors[status];
    },

    getBillingColor(sub) {
      let due = moment(sub.due);
      let diff = due.diff(moment(), 'days');

      if (diff > 5) return 'primary--text';
      if (diff <= 5) return 'warning--text';
      if (diff <= 1) return 'red--text';
    },

    getStatusColor(status) {
      return ['active', 'succeeded'].includes(status)
        ? 'primary--text'
        : 'red--text';
    },

    cancelConfirmed() {
      this.loading = true;
      Promise.resolve(this.cancelSubscription(this.toCancel))
        .then(() => {
          let emailData = {
            recipient:
              this.$store.state.settings.data.notificationRecipientEmail,
            // recipient: 'lexzstulawie@gmail.com',
            subject: 'Subscription Cancellation',
            message: `
          Hi Chris, <br/>
          ${this.user.firstName} ${
              this.user.lastName
            } has cancelled his/her subscription.<br/> <br/>

          Reason:
          ${this.answer == 'Something else' ? this.answer1 : this.answer}`,
          };
          this.$store.dispatch('mail/notify', emailData);
          this.toCancel = null;
          this.showDialog1 = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancelConfirmDialog() {
      this.showDialog = false;
      this.toCancel = null;
    },

    showConfirmDialog(sub) {
      this.showDialog = true;
      this.toCancel = sub;
    },

    showDeleteDialog() {
      this.showDialog = false;
      this.showDialog1 = true;
    },

    cancelDeleteDialog() {
      this.showDialog1 = false;
      this.toCancel = null;
    },

    showRenewDialog() {
      this.showRenew = true;
    },

    closeRenew() {
      this.showRenew = false;
    },

    price(id) {
      return this.$store.getters['pricing/price'](id);
    },
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getSubscriptions();
    this.getPayments();
    this.getTrialingPayments();
    this.getInvoices();
    this.getCards();
  },
};
</script>
