<template>
  <v-dialog
    :value="show"
    max-width="650"
    no-click-animation
    persistent
  >
    <v-card>
      <v-card-title class="mb-3">
        <span class="font-weight-bold">Renew Subscription</span>
        <v-btn
          @click="closeDialog()"
          absolute
          right
          top
          icon
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card
          v-for="plan in active"
          :key="plan.id"
          @click="setSelected(plan.id)"
          :class="{ 'selected' : plan.id == selected }"
          class="pa-5 mb-3"
          outlined
          hover
        >
          <v-simple-table transparent dense>
            <tr>
              <td cols="2">
                <h5 class="body-1 font-weight-bold">{{ plan.name }}</h5>
              </td>
            </tr>
            <tr>
              <td>Amount :</td>
              <td>${{ plan.amount | amount }}</td>
            </tr>
            <tr>
              <td>Recurring :</td>
              <td>{{ plan.recurring }}</td>
            </tr>
            <tr v-if="plan.recurring == 'yes'">
              <td>Billing Period :</td>
              <td>{{ plan.billing }}</td>
            </tr>
            <tr>
              <td>Meals :</td>
              <td>{{ plan.meals }}</td>
            </tr>
            <tr>
              <td>Days :</td>
              <td>{{ plan.days }}</td>
            </tr>
            <tr v-if="plan.recurring == 'no'">
              <td>End of Subscription :</td>
              <td>{{ due(plan) | formatDate }}</td>
            </tr>
            <tr v-if="plan.recurring == 'yes'">
              <td>Next Billing Period :</td>
              <td>{{ due(plan) | formatDate }}</td>
            </tr>
          </v-simple-table>
        </v-card>

        <div class="mt-5">
          <v-btn
            color="primary"
            :disabled="!selected"
          >Renew</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      selected: null,
    }
  },

  computed: {
    ...mapGetters('pricing', [
      'active'
    ])
  },

  methods: {
    closeDialog() {
      this.$emit('close')
    },

    due (plan) {
      let due = Date.now()
      due = moment().add(1, plan.billing ? plan.billing : 'month')

      return due
    },

    setSelected(id) {
      this.selected = id
    }
  },

  created() {
    this.$store.dispatch('pricing/getPlans')
  }
}

</script>

<style>

.selected {
  border-color: #25c16e !important;
  border-width: 2px !important;
}

</style>
